import React, { useEffect, useState } from "react";

function NoTiket() {
  const [noAntrian, setNoAntrian] = useState();
  useEffect(() => {
    setNoAntrian(sessionStorage.getItem("no_antri"));
  }, []);
  return (
    <div className="bg-grey-100">
      <div className="flex flex-col justify-center items-center w-full mt-10">
        <div className="bg-green-300 rounded-lg shadow py-5 px-10">
          <img src="/img/idi.png" className="w-[60px] mx-auto" alt="logo" />
          <div className="flex flex-row justify-center">
            <h2 className="text-lg border-b-2 border-black pb-1">
              PRAKTEK DOKTER UMUM
            </h2>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-xl font-bold pt-1 pb-8">DR. REG ADIL</p>
            <h3 className="text-lg pb-2">NO ANTRIAN</h3>
            <h1 className="text-6xl">{noAntrian}</h1>
          </div>
          <div className="pl-2 pt-10">
            <p className="text-xs text-red-600 mb-5">
              * Dimohon no antrian di screenshot / diingat
              <br />
              pada saat datang ke klinik
            </p>
            <p className="text-sm text-center">
              Jorong Rajawali, Nagari Tigo Jangko
            </p>
            <p className="text-sm text-center">Senin - Sabtu (16.00 - 20.00)</p>
            <p className="text-sm text-center">HP. 0852 6300 1004</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoTiket;
