import "./App.css";
import { Route, Routes } from "react-router-dom";
import LoginAdmin from "./pages/LoginAdmin";
import LandingPage from "./pages/LandingPage";
import NoTiket from "./pages/NoTiket";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import Dashboard from "./pages/Dashboard";
import { PersistGate } from "redux-persist/integration/react";
import AuthAdmin from "./middleware/AuthAdmin";
import AuthTicket from "./middleware/AuthTicket";
import AlreadySignin from "./middleware/AlreadySignin";

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            {/*  Auth halaman ticktet */}
            <Route path="/" element={<AuthTicket />}>
              <Route path="antrian" element={<NoTiket />} />
            </Route>
            {/* Auth Buat tidak kembali ke login */}
            <Route path="/" element={<AlreadySignin />}>
              <Route path="admin" element={<LoginAdmin />} />
            </Route>
            {/* Auth buat tidak bisa akses admin tanpa login */}
            <Route path="/" element={<AuthAdmin />}>
              <Route path="dashboard" element={<Dashboard />} />
            </Route>
            {/* <Route path="admin" element={<LoginAdmin />} />
            <Route path="dashboard" element={<Dashboard />} /> */}
          </Routes>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
