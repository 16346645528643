import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthLogin } from "../redux/action/Auth";
import Swal from "sweetalert2";

const LoginAdmin = () => {
  const { loading, data, error, isLogin } = useSelector((state) => state.auth);

  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formAddData, setFormAddData] = useState({
    username: "",
    password: "",
  });

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(AuthLogin(formAddData));
  };
  // tambah kondisi loading, data, error
  useEffect(() => {
    if (loading === true) {
      setDisable(true);
    }
    if (isLogin === true) {
      Swal.fire({
        icon: "success",
        text: "Login Berhasil!",
      });
      setDisable(false);
      navigate("/dashboard", { replace: true });
    } else if (error) {
      Swal.fire({
        icon: "error",
        title: "Login Failed",
        text: "Username atau password anda salah",
      });
      setDisable(false);
    }
  }, [data, loading, error, isLogin, navigate]);
  return (
    <>
      <div className="bg-gray-100">
        <div className="flex justify-center h-screen">
          <div className="flex w-full max-w-md px-6 mx-auto h-5/6 my-auto lg:w-3/5">
            <div className="flex-1">
              <div className="flex flex-col items-center gap-1">
                <h2
                  className="text-xl text-center text-green-600 mt-5"
                  style={{ fontWeight: 700 }}
                >
                  PRAKTEK DOKTER UMUM
                </h2>
                <img className="w-[100px]" src="/img/idi.png" alt="logo" />
                <h5 className="text-lg font-semibold">DR. REG ADIL</h5>
              </div>
              <div className="mt-10">
                <form onSubmit={(e) => handleLogin(e)}>
                  <label
                    htmlFor="text"
                    className="block mb-2 text-sm text-[#4F5665] font-bold"
                  >
                    Username
                  </label>
                  <input
                    type="text"
                    name="text"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder:text-sm bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    onChange={(e) => {
                      setFormAddData((prevData) => ({
                        ...prevData,
                        username: e.target.value,
                      }));
                    }}
                  />
                  <div className="mt-6">
                    <div className="flex justify-between mb-2">
                      <label
                        htmlFor="password"
                        className="text-sm text-[#4F5665] font-bold"
                      >
                        Password
                      </label>
                    </div>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder:text-sm bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                      onChange={(e) => {
                        setFormAddData((prevData) => ({
                          ...prevData,
                          password: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="my-10 ">
                      {
                        loading ? 
                      <button
                      className="btn loading w-full px-4 py-3 tracking-wide text-white hover:text-slate-500 font-bold transition-colors duration-200 transform bg-green-600 rounded-md hover:bg-green-500 focus:outline-none"
                      onSubmit={(e) => handleLogin(e)}
                      disabled
                    >
                      loading
                    </button> : <button
                      className="w-full px-4 py-3 tracking-wide text-white hover:text-slate-500 font-bold transition-colors duration-200 transform bg-green-600 rounded-md hover:bg-green-500 focus:outline-none"
                      onSubmit={(e) => handleLogin(e)}
                    >
                      Login
                    </button>
                      }
                    
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginAdmin;
