import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const AuthAdmin = () => {
    const {data} = useSelector(state => state.auth);
    const location = useLocation()

    return (
        data && data.data ? <Outlet /> : 
        <Navigate to="/admin" state={{from: location}} replace/>
    )
}

export default AuthAdmin