import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

const AuthTicket = () => {
    const location = useLocation()
    const ticket = sessionStorage.getItem("no_antri")
    
    return (
        ticket ?
        <Outlet /> :
        <Navigate to="/" state={{from: location}} replace/>
    )
}

export default AuthTicket