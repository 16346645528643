// Jam 4 Sore ditutup, Jam 9 malam dibuka lagi

import {
  faSpinner,
  faSquareCheck,
  faCheck,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthLogout } from "../redux/action/Auth";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {io} from "socket.io-client"
import moment from "moment";

function Dashboard() {
  const {
    loading,
    data: auth,
    error,
    isLogin,
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [err, setError] = useState(null);
  const [id, setId] = useState(0);
  const [refetch, setRefetch] = useState(false);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('antri');
  const [pasien, setPasien] = useState(null)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [socket, setSocket] = useState(null)
  const [socketData, setSocketData] = useState([]);
  const [jadwal, setJadwal] = useState("")

  useEffect(()=> {
    axios.get(`${process.env.REACT_APP_API_KLINIK}api/jadwal`)
    .then((res) => {
      setJadwal(res.data.data[0].buka)
    })
  }, [refetch])

  useEffect(() => {
    setSocket(io(process.env.REACT_APP_API_SOCKET));
  }, []);

  useEffect(() => {
    socket?.on("getMessage", (item) => {
      setSocketData([item]);
    });
  }, [socket]);

  useEffect(() => {
    status === "antri" &&
      data &&
      data.length < 5 &&
      setData((prev) => [...prev, ...socketData]);
  }, [socketData]);

  useEffect(() => {
    socket?.emit("newUser", auth.data.id);
  }, [socket, auth]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_KLINIK}api/antrian?search=${search}&status=${status}&limit=5&page=${page}`
      )
      .then((res) => {
        setSocketData([]);
        setData(res.data.data);
        setTotalPage(res.data.totalPage);
      })
      .catch((err) => {
        setError(err);
      });
  }, [refetch, status, page]);

  const handleBerobat = () => {
    axios({
      url: `${process.env.REACT_APP_API_KLINIK}api/antrian/${id}`,
      method: "PATCH",
      data: {
        status: "selesai",
      },
      headers: {
        authorization: auth.data.token,
      },
    })
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Pasien Sudah Dipanggil!",
        });
        setRefetch(!refetch);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "Server Error",
        });
      });
  };

  const handleJadwal = () => {
    axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API_KLINIK}api/jadwal`,
      data: {
        status: jadwal === "buka" ? "tutup" : "buka"
      },
      headers: {
        authorization: auth.data.token
      }
    })
    .then((res) => {
      Swal.fire({
        icon: "success",
        text: `Berhasil ${jadwal === "buka" ? "tutup" : "buka"}`
      })
      setRefetch(!refetch)
    })
  }

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    setRefetch(!refetch);
  };

  const handleDetail = (id) => {
    axios
      .get(`${process.env.REACT_APP_API_KLINIK}api/pasien/${id}`)
      .then((res) => {
        setPasien(res.data.data[0]);
      })
      .catch((err) => {
        alert("error");
      });
  };

  const handlePagination = (e) => {
    if (e.target.value === "plus") {
      totalPage === page ? (page = totalPage) : setPage((prev) => prev + 1);
    }
    if (e.target.value === "minus") {
      page === 0 ? setPage(0) : setPage((prev) => prev - 1);
    }
  };

  return (
    <main className="h-[100vh] bg-gray-100 py-5 px-3">
      <div className="flex flex-col">
        <div className="flex flex-col items-center gap-1">
          <div className="flex items-center justify-between px-8 w-full">
            <img className="w-[80px] mb-2" src="/img/idi.png" alt="logo" />
            <h2
              className="hidden md:block text-xl text-center text-green-600"
              style={{ fontWeight: 700 }}
            >
              HALAMAN ADMIN
            </h2>
            <button
              className="w-[100px] px-4 py-2 tracking-wide text-white hover:text-slate-500 font-bold transition-colors duration-200 transform bg-green-600 rounded-md hover:bg-green-500 focus:outline-none text-sm"
              onClick={() => {
                dispatch(AuthLogout());
                Swal.fire({
                  icon: "success",
                  text: "Logout Berhasil !",
                });
                navigate("/admin", { replace: true });
              }}
            >
              Logout
            </button>
          </div>
          <img className="hidden w-[80px]" src="/img/idi.png" alt="logo" />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row lg:justify-between">
        <div className="lg:w-1/2 flex flex-col h-[80vh] md:h-[80vh] justify-between">
          <div className="">
            <form
              onSubmit={handleSearch}
              className="flex justify-evenly my-6 mx-8"
            >
              <div className="dropdown">
                <label
                  tabIndex="0"
                  className="btn w-[70px] text-xs mr-3 text-white"
                >
                  {status === "antri" ? "Antrian" : "List Pasien"}
                </label>
                <ul
                  tabIndex="0"
                  className="dropdown-content menu p-5 shadow bg-base-100 rounded-box w-52"
                >
                  <li
                    key={1}
                    onClick={() => {
                      setStatus("antri");
                      setPage(1);
                    }}
                  >
                    <p>Daftar Antrian</p>
                  </li>
                  <li
                    key={2}
                    onClick={() => {
                      setStatus("selesai");
                      setPage(1);
                    }}
                  >
                    <p>Pasien Selesai</p>
                  </li>
                </ul>
              </div>
              <label className="flex items-center" htmlFor="search">
                <button type="submit" className="absolute ml-2">
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="search"
                  id="search"
                  name="search.."
                  className="block rounded-lg focus:border-blue-400 
            focus:ring-blue-400 focus:ring 
            focus:ring-opacity-40 bg-white p-2 focus:outline-none
            placeholder:italic pl-8
            "
                />
              </label>
            </form>
            <table className="border-separate border border-slate-300  mb-3 mx-auto">
              <thead>
                <tr className="bg-gray-300">
                  <th className="border border-slate-400 p-2 w-1/6">No.</th>
                  <th className="border border-slate-400 p-2 w-2/3">Nama</th>
                  <th
                    className={`${
                      status === "selesai" ? "hidden" : ""
                    } border border-slate-400 p-2 w-1/6`}
                  ></th>
                </tr>
              </thead>
              {data &&
                data.length !== 0 &&
                data.map((item) => {
                  return (
                    <>
                      <tbody key={item.id}>
                        <tr  className="bg-white lg:hidden">
                          <td className="border border-slate-400 p-2 text-center">
                            {item.no_antrian}
                          </td>
                          <td className="border border-slate-400 p-2">
                            {/* <Link to={`/dashboard/${item.id}`}> */}
                            <label
                              onClick={() => {
                                handleDetail(item.nama_id);
                              }}
                              htmlFor="my-modal"
                              className="btn justify-start btn-sm modal-button border-none w-full text-sm bg-white text-black"
                            >
                              {item.nama}
                            </label>
                            {/* </Link> */}
                          </td>
                          <td
                            className={`${
                              status === "selesai" ? "hidden" : ""
                            } border border-slate-400 p-2 w-1/6`}
                          >
                            <label
                              onClick={() => setId(item.id)}
                              htmlFor="checkModal"
                              className="btn btn-sm modal-button 
                              bg-green-600 hover:bg-green-500"
                            >
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="hover:text-gray-800"
                              />
                            </label>
                          </td>
                        </tr>
                        <tr className="hidden lg:table-row bg-white">
                          <td className="border border-slate-400 p-2 text-center">
                            {item.no_antrian}
                          </td>
                          <td className="border border-slate-400 p-2">
                            <button onClick={() => handleDetail(item.nama_id)}>
                              {item.nama}
                            </button>
                          </td>
                          <td
                            className={`${
                              status === "selesai" ? "hidden" : ""
                            } border border-slate-400 p-2 w-1/6`}
                          >
                            <label
                              onClick={() => setId(item.id)}
                              htmlFor="checkModal"
                              className="btn btn-sm modal-button 
                              bg-green-600 hover:bg-green-500"
                            >
                              <FontAwesomeIcon icon={faCheck}  />
                            </label>
                          </td>
                        </tr>
                      </tbody>
                    </>
                  );
                })}
            </table>
            {err ? (
              <div className="text-center">Server Error</div>
            ) : !data ? (
              <div className="mt-9 text-center">
                <FontAwesomeIcon icon={faSpinner} size="3x" spin />
              </div>
            ) : (
              data.length === 0 && (
                <div className="mt-5 ">
                  <h3 className="text-center font-bold">Tidak ada antrian</h3>
                </div>
              )
            )}
          </div>
          <div className="btn-group self-center" id="app">
            <button
              className={`btn btn-sm ${page === 1 ? "hidden" : ""} `}
              onClick={handlePagination}
              value="minus"
            >
              «
            </button>
            <button
              id="pagination"
              className={`btn btn-sm text-sm ${
                page === 1 ? "rounded-l-lg" : ""
              } 
           ${page === totalPage ? "rounded-r-lg" : ""}
           `}
            >
              {page}
            </button>
            <button
              className={`btn btn-sm ${page === totalPage ? "hidden" : ""} `}
              onClick={handlePagination}
              value="plus"
            >
              »
            </button>
          </div>
        </div>
        
        <div className="flex lg:w-1/2 p-5 flex-col items-center justify-center">
        <div className="hidden lg:block w-[85%] mb-9">
          <h1 className="text-center font-bold mb-4 text-lg">Detail Pasien</h1>
          <section className="bg-white px-5 py-6 rounded shadow">
            <div className="flex flex-col justify-evenly ml-5">
              <article className="flex w-[300px]">
                <p className="mr-3 font-bold mb-1">Nama : </p>
                <p>{pasien ? pasien.nama : ""}</p>
              </article>
              <article className="flex w-1/3">
                <p className="mr-3 font-bold mb-1">Umur : </p>
                <p>{pasien ? `${pasien.umur} ${pasien.tipe_umur}` : ""}</p>
              </article>
            </div>
            <div className="flex flex-col justify-evenly ml-5">
              <article className="flex w-2/3">
                <p className="mr-3 font-bold mb-1">Jenis Kelamin : </p>
                <p>{pasien ? pasien.jenis_kelamin : ""}</p>
              </article>
              <article className="flex w-1/3">
                <p className="mr-3 font-bold mb-1">Status : </p>
                <p>{pasien ? pasien.status : ""}</p>
              </article>
            </div>
            <div className="flex mb-2 ml-5">
              <article>
                <div className="mb-1 ">
                  <p>
                    <span className="font-bold">Jorong : </span>
                    {pasien ? pasien.jorong : ""}
                  </p>{" "}
                </div>
                <div className="">
                  <p>
                    <span className="font-bold">Nagari : </span>
                    {pasien ? pasien.alamat : ""}
                  </p>{" "}
                </div>
              </article>
            </div>
          </section>
        </div>
        <label htmlFor="buka-tutup-klinik" className="btn modal-button">{jadwal === "buka" ? "tutup" : "buka" } Klinik</label>    
        </div>
        
      </div>
      <input type="checkbox" id="buka-tutup-klinik" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg text-center">
            Apakah anda yakin ingin {jadwal === "buka" ? "tutup" : "buka"} klinik
          </h3>
          <div className="flex justify-center">
            <div className="modal-action ">
              <label
                htmlFor="buka-tutup-klinik"
                className="btn btn-success mr-3 
          font-bold"
                onClick={handleJadwal}
              >
                Iya
              </label>
            </div>
            <div className="modal-action">
              <label htmlFor="buka-tutup-klinik" className="btn">
                Tidak
              </label>
            </div>
          </div>
        </div>
      </div>

      <input type="checkbox" id="checkModal" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg text-center">
            Pasien selanjutnya ?
          </h3>
          <div className="flex justify-center">
            <div className="modal-action ">
              <label
                htmlFor="checkModal"
                className="btn btn-success mr-3 
          font-bold"
                onClick={handleBerobat}
              >
                Iya
              </label>
            </div>
            <div className="modal-action">
              <label htmlFor="checkModal" className="btn">
                Tidak
              </label>
            </div>
          </div>
        </div>
      </div>
      {/* Modal Detail Check */}
      <input type="checkbox" id="my-modal" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <article className="flex w-[300px]">
            <p className="mr-3 font-bold mb-1">Nama : </p>
            <p>{pasien ? pasien.nama : ""}</p>
          </article>
          <article className="flex w-1/3">
            <p className="mr-3 font-bold mb-1">Umur : </p>
            <p>{pasien ? pasien.umur : ""}</p>
          </article>
          <article className="flex w-2/3">
            <p className="mr-3 font-bold mb-1">Jenis Kelamin : </p>
            <p>{pasien ? pasien.jenis_kelamin : ""}</p>
          </article>
          <article className="flex w-1/3">
            <p className="mr-3 font-bold mb-1">Status : </p>
            <p>{pasien ? pasien.status : ""}</p>
          </article>
          <p>
            <span className="font-bold">Jorong : </span>
            {pasien ? pasien.jorong : ""}
          </p>{" "}
          <p>
            <span className="font-bold">Nagari : </span>
            {pasien ? pasien.alamat : ""}
          </p>{" "}
          <div className="modal-action">
            <label htmlFor="my-modal" className="btn">
              Tutup
            </label>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Dashboard;
