import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const AlreadySignin = () => {
    const {data} = useSelector(state => state.auth);
    const location = useLocation()

    return (
        data && data.data ? 
        <Navigate to="/dashboard" state={{from: location}} replace/> :
        <Outlet/>
    )
}

export default AlreadySignin