import axios from "axios";
import React, { useEffect, useState } from "react";
import { json, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import io from "socket.io-client"
import moment from "moment";

function LandingPage() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    jenis_kelamin: "laki-laki",
  });
  const [loading, setLoading] = useState(false);
  const [socket, setSocket] = useState(null)
  const [buka, setBuka] = useState(true);
  const [jadwal, setJadwal] = useState('')

  const time = moment().format('Hmm')
  const hari = moment().format('dddd')


  useEffect(()=> {
    axios.get(`${process.env.REACT_APP_API_KLINIK}api/jadwal`)
    .then((res) => {
      setJadwal(res.data.data[0].buka)
    })
  }, [])

  useEffect(() => {
    if(time > 1500 && time < 2100) {
      setBuka(false)
    } else if(hari.toLowerCase() === "saturday" && time > 2100) {
      setBuka(false)
    } else if(hari.toLowerCase() === "sunday" && time < 1500) {
      setBuka(false)
    }
  },[])
  
  useEffect(() => {
    setSocket(io(process.env.REACT_APP_API_SOCKET));
  }, []);

  const handleDaftar = (e) => {
    e.preventDefault();
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_API_KLINIK}api/pasien`,
      method: "POST",
      data,
    })
      .then((res) => {
        const id = res.data.data.insertId;

        axios({
          url: `${process.env.REACT_APP_API_KLINIK}api/antrian`,
          method: "POST",
          data: {
            nama: id,
          },
        })
          .then((res) => {
            setLoading(false);
            socket.emit("sendMessage", {
              id: res.data.data[0].id,
              nama_id: res.data.data[0].nama_id,
              nama: res.data.data[0].nama,
              no_antrian: res.data.data[0].no_antrian,
            });
            sessionStorage.setItem("no_antri", res.data.data[0].no_antrian);
            Swal.fire({
              icon: "success",
              text: "Daftar Berhasil",
            });
            navigate("/antrian");
          })
          .catch((err) => {
            setLoading(false);
            if (err)
              Swal.fire({
                icon: "error",
                title: "Server Error",
                text: "Server Eror",
              });
          });
      })
      .catch((err) => {
        setLoading(false);
        alert(`${err}`);
      });
  };

  return (
    <>
      {
        jadwal === "tutup" || !buka ?  
        
      <div className="h-[100vh] bg-gray-100 flex flex-col justify-center items-center">
        <h1 className="text-center font-bold text-green-700 text-lg mb-3">Praktek Dokter Umum</h1>
        <img className="w-[100px] mb-2" src="/img/idi.png" alt="logo" />
        <h5 className="text-lg font-semibold mb-2">DR. REG ADIL</h5>
        <h1 className="text-center font-bold text-red-600 text-2xl mb-3">Pendaftaran Tutup</h1>
        {jadwal === "tutup" ? 
          <h1 className="text-center font-bold text-green-600">Klinik Sedang Libur</h1> :
          <>
          <h1 className="text-center font-bold text-green-600">Pendaftaran di buka senin-sabtu </h1>
          <h1 className="text-center font-bold text-green-600">pukul  21:00 - 15:00</h1>
          </>
        }
        
      </div> : <div className="h-[100%] bg-gray-100">
        <div className="flex justify-center">
          {/* // Right Login */}
          <div className="flex w-full max-w-md px-6 p-10 mx-auto lg:w-3/5 rounded-lg border-3">
            <div className="flex-1">
              <div>
                <h2
                  className="text-3xl text-center text-green-600"
                  style={{ fontWeight: 700 }}
                >
                  ANTRIAN ONLINE
                </h2>
                <div className="flex flex-col items-center gap-1">
                  <img className="w-[100px]" src="/img/idi.png" alt="logo" />
                  <h5 className="text-lg font-semibold">DR. REG ADIL</h5>
                </div>
                <p className="mt-5 text-gray-500 text-center font-semibold">
                  Silahkan Mendaftar Untuk Mendapatkan Nomor Antrian
                </p>
              </div>
              <div className="mt-10">
                <form onSubmit={handleDaftar} action="">
                  <div className="mt-3">
                    <label
                      htmlFor="text"
                      className="block mb-2 text-sm text-[#4F5665] font-bold"
                    >
                      Nama :{" "}
                    </label>
                    <input
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          nama: e.target.value,
                        }))
                      }
                      type="text"
                      name="text"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder:text-sm bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40 "
                      required
                    />
                  </div>
                  <div className="mt-3 mb-3">
                    <div className="flex justify-between mb-2">
                      <label
                        htmlFor="jorong"
                        className="text-sm text-[#4F5665] font-bold"
                      >
                        Jorong :{" "}
                      </label>
                    </div>
                    <input
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          jorong: e.target.value,
                        }))
                      }
                      type="text"
                      name="jorong"
                      id="jorong"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder:text-sm bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                      required
                    />
                  </div>
                  <div className="mt-3 mb-3">
                    <div className="flex justify-between mb-2">
                      <label
                        htmlFor="alamat"
                        className="text-sm text-[#4F5665] font-bold"
                      >
                        Nagari :{" "}
                      </label>
                    </div>
                    <input
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          alamat: e.target.value,
                        }))
                      }
                      type="text"
                      name="alamat"
                      id="alamat"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder:text-sm bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                      required
                    />
                  </div>
                  <div className="flex justify-between ">
                      <label
                        htmlFor="umur"
                        className="text-sm text-[#4F5665] font-bold"
                      >
                        Umur :{" "}
                      </label>
                    </div>
                  <div className="flex items-center mt-3 ">
                    
                    <div className="mr-7">
                    
                    <input
                      onChange={(e) =>
                        setData((prev) => ({
                          ...prev,
                          umur: e.target.value,
                        }))
                      }
                      type="number"
                      name="umur"
                      id="umur"
                      className="block w-[50px] px-4 py-2 mt-2 text-gray-700 placeholder:text-sm bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                      min="1"
                      max="120"
                      required
                    />
                    </div>
                    <div className="">
                    <div className="mb-1">
                    <input
                        onClick={(e) => setData((prev) => ({
                        ...prev, tipe_umur: e.target.value 
                        }))}
                        id="tahun"
                        type="radio"
                        name="tipe"
                        value="tahun"
                        className="border-gray-300 mr-3"
                        defaultChecked
                      />
                      <label
                        htmlFor="tahun"
                        className="text-sm text-[#4F5665] font-bold"
                      >
                        Tahun 
                      </label>
                      </div>
                      
                      <div>
                    <input
                        onClick={(e) => setData((prev) => ({
                        ...prev, tipe_umur: e.target.value 
                        }))}
                        id="bulan"
                        type="radio"
                        name="tipe"
                        value="bulan"
                        className="border-gray-300 mr-3"
                      />
                      <label
                        htmlFor="bulan"
                        className="text-sm text-[#4F5665] font-bold"
                      >
                        bulan
                      </label>
                      </div>
                    </div>
                    
                  </div>
                  <fieldset className="mt-3">
                    <legend className="text-sm text-[#4F5665] font-bold mb-3">
                      Jenis Kelamin :{" "}
                    </legend>
                    <div className="flex items-center mb-2">
                      <input
                        onClick={(e) =>
                          setData((prev) => ({
                            ...prev,
                            jenis_kelamin: e.target.value,
                          }))
                        }
                        id="gender-option-1"
                        type="radio"
                        name="genders"
                        value="laki-laki"
                        className="border-gray-300"
                        defaultChecked
                      />
                      <label
                        htmlFor="gender-option-1"
                        className="block ml-2 font-medium text-gray-900"
                      >
                        Laki - laki
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        onClick={(e) =>
                          setData((prev) => ({
                            ...prev,
                            jenis_kelamin: e.target.value,
                          }))
                        }
                        id="gender-option-2"
                        type="radio"
                        name="genders"
                        value="perempuan"
                        className="
                        border-gray-300"
                      />
                      <label
                        htmlFor="gender-option-2"
                        className="block ml-2 font-medium text-gray-900"
                      >
                        Perempuan
                      </label>
                    </div>
                  </fieldset>
                  <fieldset className="mt-3">
                    <legend className="text-sm text-[#4F5665] font-bold mb-3">
                      Apakah anda sudah pernah berobat disini sebelumnya ?{" "}
                    </legend>
                    <div className="flex items-center mb-2">
                      <input
                        onClick={(e) =>
                          setData((prev) => ({
                            ...prev,
                            status: e.target.value,
                          }))
                        }
                        id="new-option-1"
                        type="radio"
                        name="news"
                        value="lama"
                        className="border-gray-300"
                        defaultChecked
                      />
                      <label
                        htmlFor="new-option-1"
                        className="block ml-2 font-medium text-gray-900"
                      >
                        Sudah
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        onClick={(e) =>
                          setData((prev) => ({
                            ...prev,
                            status: e.target.value,
                          }))
                        }
                        id="new-option-2"
                        type="radio"
                        name="news"
                        value="baru"
                        className="
                        border-gray-300"
                      />
                      <label
                        htmlFor="new-option-2"
                        className="block ml-2 font-medium text-gray-900"
                      >
                        Belum
                      </label>
                    </div>
                  </fieldset>
                  <div className="mt-10 ">
                    {loading ? (
                      <button
                        className="btn loading
                      w-full px-4 py-3 tracking-wide text-white font-bold transition-colors duration-200 transform bg-green-600 rounded-md  
                      "
                        disabled
                      >
                        loading
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="w-full px-4 py-3 tracking-wide text-white hover:text-slate-500 font-bold transition-colors duration-200 transform bg-green-600 rounded-md hover:bg-green-500 focus:outline-none "
                      >
                        DAFTAR
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>  
      }
      
    </>
  );
}

export default LandingPage;
